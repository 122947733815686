<!-- 举报类型 -->
<template>
  <crud :config="config"></crud>
</template>

<script>
import Crud from '@/components/crud/Crud.vue'

export default {
  name: 'Type',
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'title', name: '名称', },
        ],
        button: [

          { name: '新增', color: 'primary', icon: 'plus', event: 'inner-add' },
          { name: '编辑', color: 'primary', icon: 'edit', event: 'inner-edit', single: true },
          { name: '删除', color: 'danger', icon: 'delete', event: 'inner-delete', multiple: true },
          { name: '导出', color: 'primary', icon: 'download', event: 'inner-export' }
        ],
        table: {
          api: '/banner/banner/page',
          deleteApi: '/banner/banner/delete',
          exportApi: '/banner/banner/export',
          col: [
            { field: 'title', name: '标题' },
            { field: 'imgUrl', name: '图片',type: "img" },
            { field: 'content', name: '描述',type: 'longText' },
            { field: 'type', name: '位置' ,fmt: (value) => value==1 ? '首页' : '图谱分类' },
            { field: 'linkType', name: '类型', type: 'tag', tags: [
                { name: '不跳转' },
                { name: '外链', color: 'blue' },
                { name: '歌曲', color: 'green' },
              ]
            },
            { field: 'useFlag', name: '是否启用', type: 'switch', api: '/banner/banner/edit' },
            { field: 'createTime', name: '创建时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '编辑', event: 'inner-edit', icon: 'edit' },
                { name: '删除', event: 'inner-delete', icon: 'delete' }
              ]
            }
          ]
        },
        form: {
          addApi: '/banner/banner/add',
          editApi: '/banner/banner/edit',

          item: [
            { field: 'title', name: '标题' },
            { field: 'imgUrl', name: '图片',type: "img" },
            { field: 'content', name: '描述' ,type: 'textarea',maxlength:80,},
            { field: 'type', name: '位置',  type: 'select', option: [
                {id: 1, name: '首页'},
                {id: 2, name: '图谱分类'}
              ],required: true
            },
            { field: 'linkType', name: '类型',type: 'select',option: [
                {},
                {id: "1", name: '不跳转'},
                {id: "2", name: '外链'},
                {id: "3", name: '歌曲'},
              ],required: true},
            { field: 'useFlag', name: '是否启用', type: 'switch',default: true},
            { field: 'createTime', name: '创建时间', type: 'datetime' },
          ]
        }
      },

    }
  },
}
</script>
